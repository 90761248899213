<template>
    <small>
      <strong>User -</strong> <i>This role is created for module that has basic type. Modules that are not confidential.</i> <br>
      <strong>Super User -</strong> <i>This role is created specifically for modules that has advanced type, <br>modules that needed admin privilage and has confidential data.</i> <br>
  </small>
  </template>
  
  <script>
  export default {
      name: 'UserRoleHelp'
  }
  </script>
  
  <style>
  
  </style>
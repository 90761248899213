<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <CRow>
                <CCol lg="12">
                    <CButton
                        @click="$router.go(-1)"
                        size="sm"
                        color="warning" 
                    > 
                        <font-awesome-icon icon="arrow-left"/> Go back
                    </CButton>
                </CCol>
            </CRow>
            <br>
            <CRow>
                <CCol lg="5">
                    <CCard>
                        <CCardHeader><font-awesome-icon icon="user-cog"/> Manage User
                            <template v-if="user.status == 'Active'">
                                <CButton 
                                    v-if="$store.getters['can']('reset-password')"
                                    size="sm"
                                    color="warning" 
                                    v-show="!edit"
                                    @click="resetUserPassword()"
                                >
                                    <font-awesome-icon icon="unlock-alt"/> Reset Password
                                </CButton>
                                <CButton 
                                    size="sm"
                                    color="danger" 
                                    class="float-right" 
                                    @click="toggleEdit(), returnInformation()" 
                                    v-show="edit"
                                >
                                    <font-awesome-icon icon="window-close"/> Cancel
                                </CButton>

                                <CButton 
                                    v-if="$store.getters['can']('update-user')"
                                    size="sm"
                                    :disabled="!isValid"
                                    color="success" 
                                    class="float-right" 
                                    v-show="edit" 
                                    @click="updateUser()"
                                >
                                    <font-awesome-icon icon="save"/> Update
                                </CButton>

                                <CButton 
                                    v-if="$store.getters['can']('edit-user') && permission_loaded"
                                    size="sm"
                                    color="info" 
                                    class="float-right" 
                                    @click="toggleEdit" v-show="!edit"
                                >
                                    <font-awesome-icon icon="edit"/> Edit
                                </CButton>
                            </template>
                                
                            <template v-if="user.status == 'Archived'">
                                <CButton 
                                    v-if="$store.getters['can']('restore-user')"
                                    size="sm"
                                    color="success" 
                                    class="float-right" 
                                    @click="restore()"
                                >
                                <font-awesome-icon icon="trash-restore"/> Restore
                                </CButton>
                            </template>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol lg="8">
                                    <CInput 
                                        :disabled="disable == 1 ? true : false"
                                        :lazy="false"
                                        :value.sync="$v.user.employee_number.$model"
                                        :isValid="checkIfValid('employee_number')"
                                        label="Employee Number" 
                                        type="text" 
                                        placeholder="50012342" 
                                        autocomplete="off"  
                                        v-model="user.employee_number"
                                    />
                                </CCol>
                                <CCol lg="8">
                                    <CInput 
                                        :disabled="disable == 1 ? true : false"
                                        :lazy="false"
                                        :value.sync="$v.user.first_name.$model"
                                        :isValid="checkIfValid('first_name')"
                                        label="First name" 
                                        type="text" 
                                        placeholder="John" 
                                        autocomplete="off"  
                                        v-model="user.first_name"
                                    />
                                </CCol>
                                <CCol lg="4">
                                    <CInput 
                                        :disabled="disable == 1 ? true : false"
                                        label="Middle Name" 
                                        type="text" 
                                        autocomplete="off"  
                                        v-model="user.middle_name"
                                    />
                                </CCol>
                                <CCol lg="8">
                                    <CInput 
                                        :disabled="disable == 1 ? true : false"
                                        :lazy="false"
                                        :value.sync="$v.user.last_name.$model"
                                        :isValid="checkIfValid('last_name')"
                                        label="Last Name" 
                                        type="text" 
                                        placeholder="Doe" 
                                        autocomplete="off"  
                                        v-model="user.last_name"
                                    />
                                </CCol>
                                <CCol lg="4">
                                    <label> Suffix </label>
                                    <v-select 
                                        label="name"
                                        :disabled="disable == 1 ? true : false"
                                        v-model="user.suffix"
                                        :options="suffix"
                                    />
                                </CCol>
                                <CCol lg="12">
                                    <CFormGroup
                                        wrapperClasses="input-group pt-2"
                                        description="ex. (999) 999-9999"
                                    >
                                        <template #prepend-content>
                                        +63
                                        </template>
                                        <template #label>
                                            Contact Number
                                        </template>
                                        <template #input>
                                        <masked-input
                                            :disabled="disable == 1 ? true : false"
                                            type="tel"
                                            name="phone"
                                            class="form-control"
                                            v-model="user.contact_number"
                                            :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                                            :guide="true"
                                            placeholderChar="#"
                                        />
                                        </template>
                                    </CFormGroup>
                                </CCol>
                                <CCol lg="12">
                                    <label>Department</label>
                                    <v-select 
                                        :disabled="disable == 1 ? true : false"
                                        label="name"
                                        v-model="user.department"
                                        :options="departments_list"
                                        :class="!disable ? !user.department ? 'has-error' : 'has-success' : ''"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <br>
                                    <CCard border-color="info">
                                        <CCardHeader><font-awesome-icon icon="lock"/> User Credentials</CCardHeader>
                                        <CCardBody>
                                            <CRow>
                                                <CCol lg="12">
                                                    <CInput 
                                                        :disabled="disable == 1 ? true : false"
                                                        :lazy="false"
                                                        :value.sync="$v.user.email_address.$model"
                                                        :isValid="checkIfValid('email_address')"
                                                        label="Email Address" 
                                                        type="Email" 
                                                        placeholder="johndoe@email.com" 
                                                        autocomplete="off"  
                                                        v-model="user.email_address"
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>

                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <label>Status</label>: <CBadge :color="getColorStatus(user.status)">{{user.status}}</CBadge>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                    
                    <CRow v-show="edit">
                        <CCol lg="12">
                            <CCard borderColor="danger" v-if="$store.getters['can']('archive-user')">
                                <CCardHeader>
                                    <font-awesome-icon 
                                        icon="exclamation" 
                                        :style="{ color: 'red' }"
                                    /> 
                                    Danger Zone
                                </CCardHeader>
                                <CCardBody>
                                    <CRow>
                                        <CCol lg="8">
                                            <h6>Archive this User ?</h6>
                                        </CCol>
                                        <CCol lg="12">
                                            <label> Once archived, this user credentials cannot sign in to the system anymore. Please be certain.</label>
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <CRow>
                                        <CCol lg="3">
                                            <CButton 
                                                shape="pill" 
                                                color="warning" 
                                                @click="archive()" 
                                            > 
                                                <font-awesome-icon icon="minus-square" /> Archived 
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CCol>
                <CCol lg="7">
                    <CCard>
                        <CCardHeader><font-awesome-icon icon="user-cog"/> User Permissions / Access </CCardHeader>
                        <CCardBody v-if="data_source">
                            <template v-if="department_has_super_user_access">
                                <CRow>
                                    <CCol lg="10">
                                        <strong>User Role</strong> &nbsp;&nbsp; 
                                        <a-popover title="NOTE:" placement="right">
                                            <template slot="content">
                                                <UserRoleHelp/>
                                            </template>
                                            <font-awesome-icon 
                                                style="color: #468dc8;" 
                                                icon="question-circle" 
                                            />
                                        </a-popover>
                                    </CCol>
                                    <CCol lg="10">
                                        <h4>
                                            <p-radio 
                                                :disabled="disable == 1 ? true : false"
                                                class="p-icon p-round p-jelly" 
                                                name="role" 
                                                color="warning" 
                                                value="user" 
                                                checked="checked" 
                                                v-model="user.role">
                                                User
                                            </p-radio>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                            <p-radio 
                                                :disabled="disable == 1 ? true : false"
                                                class="p-icon p-round p-jelly" 
                                                name="role" 
                                                value="super-user" 
                                                color="danger"  
                                                v-model="user.role">
                                                Super User &nbsp; 
                                            </p-radio>
                                        </h4>
                                    </CCol>
                                </CRow>
                                <br>
                                <br>
                                <CRow>
                                    <CCol :lg="user.role != 'super-user' ? '12' : '6'">
                                        <center>
                                            <h5 v-if="data_source_for_user.length > 0"><u>USER</u> access below.</h5>
                                        </center>
                                    </CCol>
                                    <template v-if="user.role == 'super-user'">
                                        <CCol lg="6">
                                        <center>
                                            <h5 v-if="data_source_for_super_user.length > 0"><u>SUPER USER</u> access below.</h5>
                                        </center>
                                        </CCol>
                                    </template>
                                </CRow>
                            </template>

                            <CRow>
                                <CCol :lg="user.role != 'super-user' ? '12' : '6'">
                                    <CCard class="mb-0" v-for="(item, index) in data_source_for_user" v-bind:key="item.name">
                                        <CButton 
                                            block 
                                            color="link" 
                                            class="text-left shadow-none card-header" 
                                            @click="accordion = accordion === index ? false : index" 
                                        >
                                            <h5 class="m-0">{{item.name}}</h5>
                                        </CButton>
                                        <CCollapse :show="accordion === index">
                                            <CCardBody>
                                                <ejs-treeview 
                                                    :nodeClicked='nodeCheckedUserOnly' 
                                                    :disabled="disable == 1 ? true : false"
                                                    :id="'tree_view_user_'+index"
                                                    :fields="item.source" 
                                                    :showCheckBox='true'>
                                                </ejs-treeview>
                                            </CCardBody>
                                        </CCollapse>
                                    </CCard>
                                </CCol>
                                
                                <template v-if="user.role == 'super-user'">
                                    <CCol lg="6">
                                        <CCard class="mb-0" v-for="(item, index) in data_source_for_super_user" v-bind:key="item.name">
                                            <CButton 
                                                block 
                                                color="link" 
                                                class="text-left shadow-none card-header" 
                                                @click="accordion_super_user = accordion_super_user === index ? false : index" 
                                            >
                                                <h5 class="m-0">{{item.name}}</h5>
                                            </CButton>
                                            <CCollapse :show="accordion_super_user === index">
                                                <CCardBody>
                                                    <ejs-treeview 
                                                        :nodeClicked='nodeCheckedSuperUserOnly' 
                                                        :disabled="disable == 1 ? true : false"
                                                        :id="'tree_view_super_user_'+index"
                                                        :fields="item.source" 
                                                        :showCheckBox='true'>
                                                    </ejs-treeview>
                                                </CCardBody>
                                            </CCollapse>
                                        </CCard>
                                    </CCol>
                                </template>
                            </CRow>

                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>

    </div>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators"
import UserRoleHelp from './components/UserRoleHelp.vue'
export default {
    name: 'UserEdit',
    components: {
        UserRoleHelp,
    },
    data() {
        return {
            first_load: true,
            permission_loaded: false,
            disable: 1,
            accordion: 0,
            accordion_super_user: 0,
            edit: false,
            suffix: ["Jr.", "Sr.", "II", "III"],
            user: {
                id: '',
                employee_number: '',
                first_name: '',
                middle_name: null,
                last_name: '',
                contact_number: '',
                suffix: {
                    name: ''
                },
                department: {
                    id: null,
                    name: null
                },
                email_address: '',
                status: '',
                role: '',
            },
            user_backup: {
                id: '',
                employee_number: '',
                first_name: '',
                middle_name: null,
                last_name: '',
                contact_number: '',
                suffix: {
                    name: ''
                },
                department: {
                    id: null,
                    name: null
                },
                email_address: '',
                status: '',
                role: '',
            },
            permissions:[],
            departments_list: [],
            data_source: [],
            data_source_super_user:[],
            lookup_data_source:[],

            user_access: [],


        }
    },
    computed: {
        isValid () { return !this.$v.user.$invalid },
        isDirty () { return this.$v.user.$anyDirty },
        data_source_for_user: function () {
            return this.data_source.filter(x => x.source.dataSource.length > 0);
        },
        data_source_for_super_user: function () {
            return this.data_source_super_user.filter(x => x.source.dataSource.length > 0);
        },
        department_has_super_user_access: function () {
            let advance_module = this.data_source_for_super_user.filter(x => x.source.dataSource.length > 0);
            if(advance_module.length > 0) {
                return true;
            }
            return false;
        }
    },
    validations: {
        user: {
            employee_number: { required,minLength: minLength(3) },
            first_name: { required, },
            last_name: { required, },
            department: { required, },
            role: { required, },
            email_address: { required, email }
        }
    },
    created() {
        this.$Progress.start()
        this.user.id = this.$route.params.id
        this.getDepartments();
        this.getuserDetails();

    },
    methods: {
        nodeCheckedUserOnly: function(args) {
            let tree_view_name = args.node.getAttribute('id')
            let tree_view_id = tree_view_name.match(/\d+/)[0]
            let checkedNode = [args.node];
            let treeObj = document.getElementById('tree_view_user_'+tree_view_id).ej2_instances[0];
            if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
                var getNodeDetails = treeObj.getNode(args.node);
                if (getNodeDetails.isChecked == 'true') {
                    treeObj.uncheckAll(checkedNode);
                } else {
                    treeObj.checkAll(checkedNode);
                }
            }
        },
        nodeCheckedSuperUserOnly: function(args) {
            let tree_view_name = args.node.getAttribute('id')
            let tree_view_id = tree_view_name.match(/\d+/)[0]
            let checkedNode = [args.node];
            let treeObj = document.getElementById('tree_view_super_user_'+tree_view_id).ej2_instances[0];
            if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
                var getNodeDetails = treeObj.getNode(args.node);
                if (getNodeDetails.isChecked == 'true') {
                    treeObj.uncheckAll(checkedNode);
                } else {
                    treeObj.checkAll(checkedNode);
                }
            }
        },
        validate: function () {
            this.$v.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.user[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateForm: function () // JS Validation
        {
            if(
                !this.user.first_name ||
                !this.user.last_name ||
                !this.user.department ||
                !this.user.email_address
                ) return false;
            return true;
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.validate();
            }
            else {
                this.$v.$reset()
            }
        },
        returnInformation: function () {
            // ! DEEP CLONE //
            this.user = Vue.util.extend({}, this.user_backup)
            this.accordion = 0;
        },
        getDepartments: function () {
            axios.get('/departments/active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            name: response.data.data[i].name
                        }
                        this.departments_list.push(option)
                    }
                    
                this.$Progress.increase(25);
                }
            })
        },
        getuserDetails: function () {
            // this.$Progress.start()
            axios.get('/users/' + this.user.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.assignData(response.data)
                }
            })
        },
        assignData:function(response) { 

            let status = response.data.deleted_at ? 'Archived' : 'Active';
            this.user = response.data;
            this.user.email_address = response.data.emails[0].email;
            // this.user.suffix['name'] = response.data.suffix
            this.user.status = status;

            // ! DEEP CLONE //
            this.user_backup = Vue.util.extend({}, this.user)
            this.$Progress.increase(25);
            // this.$Progress.finish()
        },
        archive: function() {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to archive user <b>${this.user.first_name} ${this.user.last_name}</b>`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/users/archive/' + this.user.id, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                icon: 'success',
                                title: `User ${this.user.first_name} ${this.user.last_name} successfully archived.`,
                                timerProgressBar: true,
                            })
                            this.user.status = 'Archived';
                            this.toggleEdit()
                            this.$Progress.finish();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            // this.$swal({
            //     icon: "warning",
            //     title: "Are you sure?",
            //     html: `You are trying to archive user <b>${this.user.first_name} ${this.user.last_name}</b>`,
            //     showCancelButton: true,
            //     confirmButtonColor: "#DD6B55",
            //     confirmButtonText: "Yes",
            //     }).then((result) => {
            //     if(result.value){
            //         this.remove()
            //     }else{
            //         this.$swal('Cancelled!','','error');
            //     }
            // })
        },
        remove: function () {
            this.$Progress.start()
            axios.post('/users/archive/' + this.user.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `User ${this.user.first_name} ${this.user.last_name} successfully archived.`,
                        timerProgressBar: true,
                    })
                    this.user.status = 'Archived';
                    this.toggleEdit()
                    this.$Progress.finish();
                }
            })
        },
        restore: function () {
            this.$Progress.start()
            axios.post('/users/restore/' + this.user.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        html: `User ${this.user.first_name} ${this.user.last_name} successfully restored.`,
                        timerProgressBar:true,
                    })
                    this.user = Vue.util.extend({}, this.user_backup)
                    this.user.status =  'Active';
                    this.$Progress.finish();
                }
            })
        },
        updateUser:  function () {

            let recently_removed = [];
            let recently_added = [];
            
            let nodes = [];
            let check_nodes;
            let user_access_checker = false;
            let super_user_access_checker = false;
            this.user_access = [];
            for (let i = 0; i < this.data_source.length; i++) {
                if(document.getElementById('tree_view_user_'+i)) {
                    let tree_object = document.getElementById('tree_view_user_'+i).ej2_instances[0];
                    if(tree_object.checkedNodes.length > 0) {
                        user_access_checker = true;
                    }
                    nodes.push(tree_object.checkedNodes)
                }
            }
            for (let i = 0; i < this.data_source_for_super_user.length; i++) {
                if(document.getElementById('tree_view_super_user_'+i)) {
                    let tree_object = document.getElementById('tree_view_super_user_'+i).ej2_instances[0];
                    if(tree_object.checkedNodes.length > 0) {
                        super_user_access_checker = true;
                    }
                    nodes.push(tree_object.checkedNodes)
                }
            }

            if(this.user.role == 'user' && !user_access_checker) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Please select access for user.',
                    timerProgressBar: true,
                });
            }
            
            if(this.user.role == 'super-user' && !super_user_access_checker) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Super user role is selected, please select access for super user.',
                    timerProgressBar: true,
                });
            }

          
            check_nodes = nodes.reduce(function(arr, e) { return arr.concat(e); })
          
            for(let i = 0; i < check_nodes.length; i++) {
                this.lookup_data_source.filter((g) => { 
                    if(g.id == check_nodes[i] && !g.hasChild) {
                        let row = {
                            group_id: g.gid,
                            module_id: g.mid,
                            module_access_id: g.maid,
                        }
                        this.user_access.push(row)
                    } 
                });
            }

            this.permissions.forEach(p => {
                let found = 0;
                this.user_access.forEach(ua => {
                    if(parseInt(p.module_access_id) == ua.module_access_id) {
                        found++;
                    }
                });
                if(found==0)
                {
                    recently_removed.push(p.id)
                }
            });

            this.user_access.forEach(ua => {
                let found = 0;
                this.permissions.forEach(p => {
                    if(ua.module_access_id == parseInt(p.module_access_id)) {
                        found++;
                    }
                });
                if(found==0)
                {
                    recently_added.push(ua)
                }
            });
                
            if(JSON.stringify(this.user_backup) == JSON.stringify(this.user) && recently_added.length == 0 && recently_removed.length == 0) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            let _data = {
                user_id: this.user.id, 
                recently_added: recently_added, 
                recently_removed: recently_removed
            }
    
            

            if(this.validateForm()){
                // if(check_nodes.length <= 0) {
                //     return this.$swal({
                //         toast: true,
                //         position: 'top-right',
                //         showConfirmButton: false,
                //         timer: 3000,
                //         icon: 'error',
                //         title: 'Please add user permission / access.',
                //         timerProgressBar: true,
                //     });
                // }
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to update a user.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return this.updateUserDetails().then(() => {
                            return this.updateUserAccess(_data).then((response) => {
                                this.$Progress.finish()
                                this.assignData(response.data)
                                this.toggleEdit();
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    html: `<b>${this.user.first_name} ${this.user.last_name}</b> has been updated.`,
                                })
                            })
                        })
                    }
                    }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    timerProgressBar: true,
                }) 
            return;
        },
        updateUserDetails: function () {
            return new Promise((resolve, reject) => {
                axios.post('/users/update/' + this.user.id, this.user, {validateStatus: () => true})
                .then(response => {
                    if ( response.status == 200 ) {
                        resolve (response)
                    }
                })
            })
        },
        updateUserAccess: function (data) {
            return new Promise((resolve, reject) => {
                axios.post('/permissions/update',  data, {validateStatus: () => true})
                .then(res => {
                    if(res.status == 200) {
                        resolve(res)
                    }
                })
            })
        },
        getUserPermissions: function () {
            if(!this.first_load) this.$Progress.start();
            axios.get('/permissions/' + this.user.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.permissions = response.data.data
                    if(!this.first_load) this.$Progress.increase(50);
                    if(this.first_load) this.$Progress.increase(25);
                }
            })
        },
        getDepartmentAccess: function () {
            // this.$Progress.start()
            axios.get('/permissions/list/'+this.user.department.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.department_access = response.data.data;
                    this.manipulatePermissions();
                }
            })
        },
        manipulatePermissions: function () {
            let data = this.department_access.groups;
            this.lookup_data_source = [];
            this.data_source = [];
            this.data_source_super_user = [];
            let source;
            let source_super_user;
            let permission_rows;
            let permission_rows_super_user;
            let row_count = 0;
            if(data.length > 0) {
                for(let g = 0; g < data.length; g++) {
                    source = [];
                    source_super_user = [];
                    let m_row;
                    let ma_row;
                    for(let m = 0; m < data[g].modules.length; m++) {
                        row_count++;
                        m_row = {
                            gid: data[g].id,
                            mid: data[g].modules[m].id,
                            tid: data[g].modules[m].id,
                            id: row_count,
                            name: data[g].modules[m].name,
                            hasChild: true,
                        }
                        
                        if(data[g].modules[m].type == 'basic') {
                            source.push(m_row)
                            this.lookup_data_source.push(m_row)
                            for (let ma = 0; ma < data[g].modules[m].module_access.length; ma++) {
                                row_count++;
                                if(this.permissions.length > 0) {
                                    for(let p = 0; p < this.permissions.length; p++) {
                                        ma_row = {
                                            gid: data[g].id,
                                            mid: data[g].modules[m].id,
                                            tid: data[g].modules[m].module_access[ma].id,
                                            id: row_count,
                                            pid: m_row.id,
                                            name: data[g].modules[m].module_access[ma].name,
                                            maid: data[g].modules[m].module_access[ma].id,
                                        }
                                        if(data[g].modules[m].module_access[ma].id === parseInt(this.permissions[p].module_access_id)) {
                                            ma_row.isChecked = true;
                                            break;
                                        }
                                    }
                                } else {
                                    ma_row = {
                                        gid: data[g].id,
                                        mid: data[g].modules[m].id,
                                        tid: data[g].modules[m].module_access[ma].id,
                                        id: row_count,
                                        pid: m_row.id,
                                        name: data[g].modules[m].module_access[ma].name,
                                        maid: data[g].modules[m].module_access[ma].id,
                                        isChecked: false,
                                    }
                                }
                                source.push(ma_row)
                                this.lookup_data_source.push(ma_row)
                            }
                        } else {
                            source_super_user.push(m_row)
                            this.lookup_data_source.push(m_row)
                            for (let ma = 0; ma < data[g].modules[m].module_access.length; ma++) {
                                row_count++;
                                if(this.permissions.length > 0) {
                                    for(let p = 0; p < this.permissions.length; p++) {
                                        ma_row = {
                                            gid: data[g].id,
                                            mid: data[g].modules[m].id,
                                            tid: data[g].modules[m].module_access[ma].id,
                                            id: row_count,
                                            pid: m_row.id,
                                            name: data[g].modules[m].module_access[ma].name,
                                            maid: data[g].modules[m].module_access[ma].id,
                                        }
                                        if(data[g].modules[m].module_access[ma].id === parseInt(this.permissions[p].module_access_id)) {
                                            ma_row.isChecked = true;
                                            break;
                                        }
                                    }
                                } else {
                                    ma_row = {
                                        gid: data[g].id,
                                        mid: data[g].modules[m].id,
                                        tid: data[g].modules[m].module_access[ma].id,
                                        id: row_count,
                                        pid: m_row.id,
                                        name: data[g].modules[m].module_access[ma].name,
                                        maid: data[g].modules[m].module_access[ma].id,
                                        isChecked: false,
                                    }
                                }
                                source_super_user.push(ma_row)
                                this.lookup_data_source.push(ma_row)
                            }
                        }

                        
                    }
                    let format = {
                        dataSource: source, 
                        id: 'id', 
                        parentID: 'pid', 
                        text: 'name', 
                        hasChildren: 'hasChild',
                        tableID: 'tid',
                        group_id: 'gid',
                        module_id: 'mid',
                        module_access_id: 'maid',
                    }
                    permission_rows = {
                        id: data[g].id,
                        name: data[g].name,
                        source: format,
                    }
                    this.data_source.push(permission_rows);


                    let format_super_user = {
                        dataSource: source_super_user, 
                        id: 'id', 
                        parentID: 'pid', 
                        text: 'name', 
                        hasChildren: 'hasChild',
                        tableID: 'tid',
                        group_id: 'gid',
                        module_id: 'mid',
                        module_access_id: 'maid',
                    }
                    permission_rows_super_user = {
                        id: data[g].id,
                        name: data[g].name,
                        source: format_super_user,
                    }
                    this.data_source_super_user.push(permission_rows_super_user);
                }
            }
            this.permission_loaded = true;
            if(!this.first_load) this.$Progress.increase(50);
            if(!this.first_load) this.$Progress.increase(25);
            this.$Progress.finish();
            
            this.first_load = false;
        },
        resetUserPassword: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to reset the password of 
                <br>
                <b>${this.user.first_name} ${this.user.last_name}${this.user.suffix ? ' '+this.user.suffix: ''}</b>. 
                <br><br>
                <small>Note that this action <u>cannot be undone.</u> Please be certain.</small>
                `,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/users/reset-password', {user_id: this.user.id}, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.user.first_name} ${this.user.last_name}${this.user.suffix ? ' '+this.user.suffix: ''}</b> password has been reset.`,
                            })
                            this.$Progress.finish()
                            this.getuserDetails();
                        }
                    })
                }
                }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        }
    },
    watch: {
        'user.department': function () {
            if(this.user.department) {
                this.getUserPermissions()
            } else {
                this.first_load = false;
                this.permission_loaded = true;
                this.$Progress.finish();
            }
        },
        permissions: function (value) {
            this.getDepartmentAccess();
        }
    }
}
</script>